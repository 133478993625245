export default {
	data () {
		return {

			/* For CLIENT in GDPR, see the translation files */


			/* SCRIPTS */
			thereIsFloodLightScriptInThisAction: false,


			/* BREADCRUMBS */
			displayBreadCrumbs: true,
			listOfCrumbsForThisAction: ['store', 'form', 'survey', 'cashback'],
			 /* INFO Possibles values : 'info', 'registration', 'store', 'form', 'survey', 'summary', 'cashback' */
			


			/* ? Allow a coupon in the mail ?*/
			thereIsASurveyInThisAction: false,

			/* Don't forget to check on mixins "ga" and "cf" for google analytics and cookie first */

			/* List of fields that must be valid for a specific button to unlock */
			/* ! Country must not be required when the google API put the value in the field, as it is not validated by action api then. */
			/* TODO: find a way to make action api validate that field */

			mustBeValidForPage: {
				'btn_cashback_on_form1' : ['gender','firstName', 'lastName', 'email','street1','houseNumber','postalCode','city','file', 'conditions'],
				'btn_cashback_on_form2' : ['idLeadTMD1', 'idLeadTMD2', 'idLeadTMD3'],
				//'btn_cashback_on_survey': ['idLeadTMD1', 'idLeadTMD2', 'idLeadTMD3'],
			},

			// ! Maybe I can compose this programmatically ;)
			allRequiredFields: ['gender','firstName', 'lastName', 'email','street1','houseNumber','postalCode','city','file', 'conditions','idLeadTMD1', 'idLeadTMD2', 'idLeadTMD3'],

		}
	},
}
