<template>
	<div id="classicFooter">

		<ul>
			<li>
				<a :href="$t('footer.genterms')" @click.prevent="openPDFFile('conditions')" download>{{$t('footer.genterms')}}</a>
			</li>
			<li>
				<a :href="$t('footer.privacy.link')" target="_blank" rel="noopener">{{$t('footer.privacy.label')}}</a>
			</li>
			<li>
				<a :href="$t('footer.conditions.link')" target="_blank" rel="noopener">{{$t('footer.conditions.label')}}</a>
			</li>
			<li>
				<a :href="$t('footer.contact.link')" target="_blank" rel="noopener">{{$t('footer.contact.label')}}</a>
			</li>
		</ul>

	</div>
</template>

<!-- ================================================================================== -->

<script>
	import openPDF from "../mixins/openpdf";

	export default {
		name:"ClassicFooter",
		mixins: [openPDF],
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
