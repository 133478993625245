import config from "../config";

export default {
	data() {
		return {
			/* === modifiable data =========================*/

			/* G.A. for HighCo Data (must be false for P&G actions) */
			highcoGaMustBePresent: false,
			gaHighco: 'UA-208926391-10', 
			/* G.A. for client */
			clientGaMustBePresent: false,
			gaClient: 'UA-32832439-152',

			gtm: 'GTM-N94XXFB',
			dc: 'GTM-N94XXFB',


			/* === don't modify below this point =========================*/		
			consentOverlayID: 'htmllang/9e403a15-f287-4770-8f16-d858c915d608',
			lang: '',
			country: '',
			loaded: false,
		}
	},
	methods: {



		insertOneTrustTag(){
		  this.setLangVariables()
		  let script = document.createElement('script')
		  script.innerHTML = `
		  var PGdataLayer =
		  {
			GTM: 
			{
				ConsentOverlay: "OneTrust",
				ConsentOverlayID: "${this.consentOverlayIDComputed}",
				SiteBrand: "Ucran",
				SiteCountry: "${this.country}",
				SiteEnvironment: "Prod",
				SiteHost: "highactions",
				SiteLanguage: "${this.lang}",
				SiteLocalContainer: "",
				SiteLocale: "${this.fullLangString}",
				SitePlatform: "",
				SitePrivacyProtection: "GDPR",
				SiteStatus: "Live",
				SiteTechnicalAgency: "HighCoData",
				SiteTouchpoint: "",
				GoogleAnalyticsLocal: "${this.gaClient}",
				GoogleAnalyticsConsentRequired: "true",
				GoogleAnalyticsBrand: "",
				GoogleAnalyticsSiteSpeedSampleRate: "",
				GoogleAnalyticsAllowLinker: "",
				GoogleAnalyticsLinkerDomains: "",
				GoogleAnalyticsEnableOptimizely: "",
				GoogleAnalyticsOptimizeContainerID: "",
				GoogleAnalyticsReportingView: "",
				FacebookConnectAppID: "",
				FacebookConnectLocale: "",
				FacebookRemarketingID: "",
				BINPlatform: "",
				GoogleReCaptcha: ""
			}
		}
		`;
		document.head.prepend(script);
	},




	insertGtmTag() {
		// GTM script 
		let script = document.createElement('script')
		script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
		var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
		j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
		f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${this.gtm}');`;
		document.head.appendChild(script);

		// GTM noscript 
		let noscript = document.createElement('noscript');
		noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.gtm}" 
		height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
		document.body.prepend(noscript);
	},






		insertGTag() {
			let script = document.createElement('script');
			script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this.dc}`);
			script.setAttribute('async', '');
			document.head.appendChild(script);

			script = document.createElement('script');
			script.innerHTML = `window.dataLayer = window.dataLayer || []; 
			function gtag(){dataLayer.push(arguments);} 
			gtag('js', new Date()); 
			gtag('config', '${this.dc}');`;
			document.head.appendChild(script);
		},

		insertHighcoGaTag() {
			let script = document.createElement('script');
			script.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this.gaHighco}`);
			script.setAttribute('async', '');
			document.head.appendChild(script);

			script = document.createElement('script');
			script.innerHTML = `window.dataLayer = window.dataLayer || []; 
			function gtag(){dataLayer.push(arguments);} 
			gtag('js', new Date()); 
			gtag('config', '${this.gaHighco}');`;
			document.head.appendChild(script);
		},

		insertNoScript(){
			let noscript = document.createElement('noscript');
			noscript.innerHTML = '<img src="https://ad.doubleclick.net/ddm/activity/src=11232842;type=invmedia;cat=flood0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>';
			document.body.prepend(noscript);
		},



		updatePGDataLayer() {
		/* Must get the correct new destination language */
			this.setLangVariables();
		/* Only then… */
			if (window.OneTrust) {
				window.OneTrust.changeLanguage(this.lang);
			}
		},
		setLangVariables() {
			let helper = this.$i18n.locale.split('_');
			this.lang = helper[0];
			this.country = helper[1];
			document.getElementsByTagName('html')[0].lang = this.lang;
		},
	},
	watch: {
		'$i18n.locale': {
			handler: function () {
				this.updatePGDataLayer();
			},
		},
	},
	computed: {
		fullLangString () {
			return this.lang + '_' + this.country;
		},
		consentOverlayIDComputed () {
			if (process.env.NODE_ENV !== 'production') {
				return this.consentOverlayID + '-test';
			}
			return this.consentOverlayID;
		}
	},
	beforeMount() {

		if(config.isWebsiteInProduction){
		/* Insert GA Tag for CLIENT */
			if(this.clientGaMustBePresent){
				this.insertGTag();
			}
		/* Insert GA Tag for HIGHCO */
			if(this.highcoGaMustBePresent){
				this.insertHighcoGaTag();
			}
		/* Insert a noscript, just in case */
			this.insertNoScript();


		/* ! For P&G ONLY */
		/* Uncomment the two block above. Then… */

	   	this.insertOneTrustTag();
	   	this.insertGtmTag();

		}
	},
	mounted() {
		this.loaded = true
	}
}
